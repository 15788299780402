<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t(`cip.plat.wfOps.process.title.indexHeadTitle`)"
                 @head-delete="handleDeleteProcess"
                 @head-add="headAdd"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @row-detail="rowTrackPending"
                 @row-flow="handleFlow"
                 @row-del="handleDeleteProcess"></grid-layout>
    <!--    <basic-container>
    <avue-crud ref="crud"
               :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               v-model="form"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="onLoad(page, query)"
               @on-load="onLoad">
      <template #processDefinitionName="{row}">
        <el-link v-if="permission.wf_ops_detail"
                 style="font-size: 12px;"
                 type="primary"
                 @click="handleDetail(row)">{{row.processDefinitionName}}</el-link>
        <span v-else>{{row.processDefinitionName}}</span>
      </template>
      <template #menu="{row}">
        <el-button v-if="permission.wf_ops_follow"
                   type="text"
                   size="small"
                   icon="el-icon-search"
                   @click="handleFlow(row)">流程图</el-button>
        <el-button v-if="permission.wf_ops_delete_process"
                   type="text"
                   size="small"
                   icon="el-icon-delete"
                   @click="handleDeleteProcess(row)">删除</el-button>
      </template>
      <template #menuLeft>
        <el-button v-if="permission.wf_ops_delete_process"
                   type="danger"
                   size="mini"
                   icon="el-icon-delete"
                   @click="handleDeleteProcess()">删除</el-button>
      </template>
    </avue-crud>


  </basic-container>-->
    <CommonDialog v-if="bpmnVisible" customClass="wf-dialog" width="50%" :showBtn="false" :dialogTitle="$t(`cip.plat.wfOps.process.title.flowChartHeadTitle`)" @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 500px;"
                 :options="bpmnOption"></wf-design>
    </CommonDialog>
    <el-drawer :visible.sync="detailVisible"
               :title="form.processDefinitionName"
               custom-class="wf-drawer"
               size="60%"
               append-to-body>
      <task-detail v-if="detailVisible"
                   :taskId="form.taskId"
                   :processInstanceId="form.processInstanceId"></task-detail>
    </el-drawer>
  </div>

</template>

<script>
import { detail } from '@/api/plugin/workflow/process'
import { processList as getList, deleteProcess } from "@/api/plugin/workflow/ops";
import { mapGetters } from "vuex";

import TaskDetail from './detail.vue'
import exForm from '../mixins/ex-form'
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import website from "@/config/website";
import CommonDialog from "@/components/CommonDialog"
export default {
  mixins: [exForm],
  components: {
    TaskDetail, GridLayout, HeadLayout, CommonDialog
  },
  data () {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        selection: true,
        dialogType: 'drawer',
        addBtn: false,
        editBtn: false,
        delBtn: false,
        align: 'center',
        searchSize: 'mini',
        searchIndex: 3,
        searchIcon: true,
        menuWidth:150,
        column: [
          {
            label: this.$t('cip.plat.wfOps.process.field.nameOfTheProcess'),
            prop: 'processDefinitionName',
            search: true,
            overHidden: true,
            width: 150,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.ProcessIdentification'),
            prop: 'processDefinitionKey',
            search: true,
            overHidden: true,
            width: 150,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.SerialNumber'),
            prop: 'serialNumber',
            bind: 'variables.serialNumber',
            search: true,
            overHidden: true,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.category'),
            align:'center',
            row: true,
            type: 'tree',
            overHidden: true,
            width: 150,
            dicUrl: '/api/sinoma-workflow/design/category/tree',
            props: {
              label: 'name',
              value: 'id'
            },
            prop: "category",
            search: true,
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.applicant'),
            prop: 'startUsername',
            search: true,
            align:'center',
            overHidden: true,
            width: 200,
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.StartTime'),
            prop: 'createTime',
            align:'center',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            width: 165,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.EndTime'),
            prop: 'endTime',
            align:'center',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            overHidden: true,
            width: 165,
          },
          {
            label:this.$t('cip.plat.wfOps.process.field.StartTime'),
            align:'center',
            prop: 'startTimeRange',
            type: 'datetime',
            dataType: 'string',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            hide: true,
            search: true,
            searchRange: true,
            overHidden: true,
            width: 165,
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.EndTime'),
            prop: 'endTimeRange',
            align:'center',
            type: 'datetime',
            dataType: 'string',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            hide: true,
            search: true,
            searchRange: true,
            overHidden: true,
            width: 165,
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.ProcessStatus'),
            prop: 'processIsFinished',
            align:'center',
            overHidden: true,
            width: 165,
            dicData: [{
              label: this.$t('cip.plat.wfOps.process.field.unfinished'),
              value: 'unfinished'
            }, {
              label: this.$t('cip.plat.wfOps.process.field.finished'),
              value: 'finished'
            }, {
              label: this.$t('cip.plat.wfOps.process.field.terminate'),
              value: 'terminate'
            }, {
              label: this.$t('cip.plat.wfOps.process.field.withdraw'),
              value: 'withdraw'
            }, {
              label: this.$t('cip.plat.wfOps.process.field.recall'),
              value: 'recall'
            }, {
              label: this.$t('cip.plat.wfOps.process.field.reject'),
              value: 'reject'
            }, {
              label: this.$t('cip.plat.wfOps.process.field.deleted'),
              value: 'deleted'
            }],
            type: 'select',
            search: true
          },
          {
            label: this.$t('cip.plat.wfOps.process.field.detailedCirculation'),
            prop: 'flow',
            overHidden: true,
            width: 165,
            align:'center',
          }
        ]
      },
      data: [],
      bpmnVisible: false,
      bpmnOption: {},
      detailVisible: false
    };
  },
  computed: {
    ...mapGetters(["permission","language"]),
    ids () {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.processInstanceId);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: this.$t('cip.plat.wfOps.process.field.nameOfTheProcess'),
          prop: "processDefinitionName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.plat.wfOps.process.msg.allName'),
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      if (this.permission.wf_ops_delete_process) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-delete",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {
      let result = [];
      if (this.permission.ops_detail) {
        result.push({
          label: this.$t('cip.plat.wfOps.process.btn.detailBtn'),
          emit: "row-detail",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.ops_flow) {
        result.push({
          label: this.$t('cip.plat.wfOps.process.btn.flowBtn'),
          emit: "row-flow",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_ops_delete_process) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-del",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      return result;
    },
  },
  mounted () {
    this.onLoad(this.page, {});
  },
  methods: {
    headAdd(){
      this.$router.push({
        path: '/plugin/workflow/ops/proxyEdit'
      })
    },
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    handleDeleteProcess (row) {
      let processInstanceId
      if (row && row.processInstanceId) {
        processInstanceId = row.processInstanceId
      } else {
        if (this.ids.length === 0) {
          this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`))
          return
        }
        processInstanceId = this.ids
      }
      this.$confirm(this.$t('cip.plat.wfOps.process.msg.remove'), {
        confirmButtonText: this.$t('cip.plat.wfOps.process.btn.logicalDeletionBtn'),
        cancelButtonText: this.$t('cip.plat.wfOps.process.btn.physicalDeletionBtn'),
        type: 'warning',
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$prompt(this.$t('cip.plat.wfOps.process.msg.reason'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
          inputErrorMessage: this.$t('cip.plat.wfOps.process.msg.reason')
        }).then(({ value }) => {
          deleteProcess({ processInstanceId, comment: value }).then(() => {
            this.$message.success(this.$t('cip.plat.wfOps.process.msg.reasonSuccess'))
            this.onLoad(this.page, this.query)
          })
        }).catch(() => {
        })
      }).catch((action) => {
        if (action == 'cancel') {
          this.$confirm(this.$t('cip.plat.wfOps.process.msg.reconfirm'), {
            type: 'warning'
          }).then(() => {
            deleteProcess({ processInstanceId, status: 'force' }).then(() => {
              this.$message.success(this.$t('cip.plat.wfOps.process.msg.reasonSuccess'))
              this.onLoad(this.page, this.query)
            })
          }).catch(() => {
          })
        }
      })
    },
    handleDetail (row) {
      this.dynamicRoute(row, 'detail', true).then(() => {
        this.form = { ...row }
        this.detailVisible = true
      })
    },
    rowTrackPending(row) {
      const {taskId, processInstanceId} = row;
      detail({taskId, processInsId: processInstanceId}).then((res) => {
        const {process, flow} = res.data.data;
        const { id, taskId, processInstanceId, processId, processDefKey } =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl = window.location.origin + '#/process/external/iframe/detail?p=' + param + '&isNewWindow=true';
        // let sUrl = 'http://localhost:1888/#/process/external/iframe/detail?p=' + param;
        window.open(sUrl, "_blank");
      });
    },
    handleFlow (row) {
      const { taskId, processInstanceId } = row
      detail({ taskId, processInsId: processInstanceId }).then(res => {
        const { process, flow } = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },

    async onLoad (page, params = {}) {
      this.page = page
      this.loading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data
        this.$refs.gridLayOut.page.total = data.total
        this.data = data.records
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
};
</script>

<style lang="scss">
.wf-drawer {
  .el-drawer__header {
    padding: 10px !important;
    color: #333333;
    font-size: 25px;
    margin: 10px 0px 0px !important;
  }
  .el-drawer__body {
    padding: 10px !important;
    overflow: auto;
  }
}
</style>
<style scoped>
::v-deep .bjs-powered-by {
  display: none;
}
</style>
